// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Recursive_VF.woff2?v=1.085", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* See https://github.com/arrowtype/recursive/tree/main#opentype-features for font-feature-settings examples & more configuration info */\n@font-face {\n  font-family: 'Recursive var';\n  font-weight: 300 1000;\n  font-display: swap;\n  font-style: oblique 0deg 15deg;\n  font-variation-settings: 'MONO' 1, 'CASL' 0, 'slnt' 0, 'CRSV' 0;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");\n  font-feature-settings: \"ss02\", \"ss08\", \"ss12\";\n}", "",{"version":3,"sources":["webpack://./src/assets/fonts/Recursive/recursive.css"],"names":[],"mappings":"AAAA,wIAAwI;AACxI;EACE,4BAA4B;EAC5B,qBAAqB;EACrB,kBAAkB;EAClB,8BAA8B;EAC9B,+DAA+D;EAC/D,4DAAwD;EACxD,6CAA6C;AAC/C","sourcesContent":["/* See https://github.com/arrowtype/recursive/tree/main#opentype-features for font-feature-settings examples & more configuration info */\n@font-face {\n  font-family: 'Recursive var';\n  font-weight: 300 1000;\n  font-display: swap;\n  font-style: oblique 0deg 15deg;\n  font-variation-settings: 'MONO' 1, 'CASL' 0, 'slnt' 0, 'CRSV' 0;\n  src: url(\"./Recursive_VF.woff2?v=1.085\") format(\"woff2\");\n  font-feature-settings: \"ss02\", \"ss08\", \"ss12\";\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
