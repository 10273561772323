// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Inter-roman.var.woff2?v=3.19", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Inter-italic.var.woff2?v=3.19", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Inter var';\n  font-weight: 100 900;\n  font-display: swap;\n  font-style: normal;\n  font-named-instance: 'Regular';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");\n}\n@font-face {\n  font-family: 'Inter var';\n  font-weight: 100 900;\n  font-display: swap;\n  font-style: italic;\n  font-named-instance: 'Italic';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");\n}", "",{"version":3,"sources":["webpack://./src/assets/fonts/Inter/inter.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,oBAAoB;EACpB,kBAAkB;EAClB,kBAAkB;EAClB,8BAA8B;EAC9B,4DAAwD;AAC1D;AACA;EACE,wBAAwB;EACxB,oBAAoB;EACpB,kBAAkB;EAClB,kBAAkB;EAClB,6BAA6B;EAC7B,4DAAyD;AAC3D","sourcesContent":["@font-face {\n  font-family: 'Inter var';\n  font-weight: 100 900;\n  font-display: swap;\n  font-style: normal;\n  font-named-instance: 'Regular';\n  src: url(\"Inter-roman.var.woff2?v=3.19\") format(\"woff2\");\n}\n@font-face {\n  font-family: 'Inter var';\n  font-weight: 100 900;\n  font-display: swap;\n  font-style: italic;\n  font-named-instance: 'Italic';\n  src: url(\"Inter-italic.var.woff2?v=3.19\") format(\"woff2\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
